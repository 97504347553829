import logoPath from "@/img/logo.svg";
import { UploadsProvider } from "@cospex/client/converter/context/UploadsProvider";
import ConverterConfigProvider from "@cospex/client/converter/provider";
import { initDictionary } from "@cospex/client/helpers/dictionary";
import GlobalProvider from "@cospex/client/provider";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router/lazy";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;

const languages = [
  { value: "en", label: "EN 🇬🇧" },
  { value: "fr", label: "FR 🇫🇷" },
  { value: "it", label: "IT 🇮🇹" },
  { value: "de", label: "DE 🇩🇪" },
  { value: "es", label: "ES 🇪🇸" },
];

initDictionary(languages);

createRoot(container).render(
  <GlobalProvider
    theme={theme}
    config={{
      logo: {
        src: logoPath,
      },
      languages,
    }}
  >
    <ConverterConfigProvider>
      <UploadsProvider>
        <Routes />
      </UploadsProvider>
    </ConverterConfigProvider>
  </GlobalProvider>
);
