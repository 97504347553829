import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "footer-description":
      "{{name}} is a new-generation conversion software that lets you convert your files into any format.",
    "footer-link-cookies": "Cookies",
    "footer-link-privacy": "Privacy",
    "footer-link-terms": "Terms and conditions",
    "footer-link-contact": "Contact",
    "footer-link-convert-specific": "Convert {{from}} to {{to}}",
    "footer-link-compress-specific": "Compress {{to}}",
    "footer-link-pricing": "Pricing",
    "footer-link-refunds": "Refunds",
    "footer-link-login": "Login",
    "footer-link-unsubscribe": "Unsubscribe",
  },
  fr: {
    "footer-description":
      "{{name}} est un logiciel de conversion de nouvelle génération qui vous permet de convertir vos fichiers dans n'importe quel format.",
    "footer-link-cookies": "Cookies",
    "footer-link-privacy": "Confidentialité",
    "footer-link-terms": "Termes et conditions",
    "footer-link-contact": "Contact",
    "footer-link-convert-specific": "Convertir un {{from}} en {{to}}",
    "footer-link-compress-specific": "Compresser un {{to}}",
    "footer-link-pricing": "Tarifs",
    "footer-link-refunds": "Remboursements",
    "footer-link-login": "Se connecter",
    "footer-link-unsubscribe": "Se désabonner",
  },
  it: {
    "footer-description":
      "{{name}} è il software di conversione di nuova generazione che ti permette di convertire i tuoi file in qualsiasi formato.",
    "footer-link-cookies": "Cookie",
    "footer-link-privacy": "Privacy",
    "footer-link-terms": "Termini e condizioni",
    "footer-link-contact": "Contattaci",
    "footer-link-convert-specific": "Converti da {{from}} in {{to}}",
    "footer-link-compress-specific": "Optimizza un {{to}}",
    "footer-link-pricing": "Prezzi",
    "footer-link-refunds": "Rimborsi",
    "footer-link-login": "Accesso",
    "footer-link-unsubscribe": "Disdetta",
  },
  es: {
    "footer-description":
      "{{name}} es un software de conversión de nueva generación que te permite convertir tus archivos a cualquier formato.",
    "footer-link-cookies": "Cookies",
    "footer-link-privacy": "Privacidad",
    "footer-link-terms": "Términos y condiciones",
    "footer-link-contact": "Contacto",
    "footer-link-convert-specific": "Convierte {{from}} a {{to}}",
    "footer-link-compress-specific": "Comprimir {{to}}",
    "footer-link-pricing": "Precios",
    "footer-link-refunds": "Reembolso",
    "footer-link-login": "Acceso",
    "footer-link-unsubscribe": "Cancelaciones",
  },
  de: {
    "footer-description":
      "{{name}} ist eine Konvertierungssoftware der neuen Generation, mit der Sie Ihre Dateien in jedes beliebige Format konvertieren können.",
    "footer-link-cookies": "Cookies",
    "footer-link-privacy": "Datenschutz",
    "footer-link-terms": "Allgemeine Geschäftsbedingungen",
    "footer-link-contact": "Kontakt",
    "footer-link-convert-specific": "{{from}} in {{to}}",
    "footer-link-compress-specific": "Komprimieren {{to}}",
    "footer-link-pricing": "Preise",
    "footer-link-refunds": "Erstattungen",
    "footer-link-login": "Login",
    "footer-link-unsubscribe": "Kündigen",
  },
};
const hook = addDictionary(dictionary, "converter/components/Footer");
export default hook;
